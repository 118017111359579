import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemContext } from "../../../context/ItemContext";
import { Item } from "../../../domain/Item";
import { ItemStock } from "../../../service/ItemStock";
import { ItemProp } from "../../common/item/ItemCard";
import ItemList from "../../common/item/ItemList";

const FetchRelatedItemList = () => {
  const [items, setItems] = useState<Array<Item>>([]);
  const { id } = useParams();
  const { itemType } = useContext(ItemContext);

  useEffect(() => {
    ItemStock.check().getItem(itemType).then(({ items }) => {
      setItems(items.filter((item, i) => item.itemType === itemType && item.id !== id));
    }).catch((e) => { console.log(e); setItems([]); });
  }, [id, itemType]);

  return (
    <>
      <h2 className="text-uppercase">Related Items</h2>
      {items.length === 0 && <div className="text-secondary">No item</div>}
      <ItemList items={items as ItemProp[]} enableLoadMore={false} perPage={4} />
    </>
  );
};

export default FetchRelatedItemList;
