import { Container } from "react-bootstrap";
import { ChevronCompactRight } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumb.scss";

const Breadcrumb = () => {
  const paths = decodeURI(useLocation().pathname).split("/");

  return (
    <div className="pdy-breadcrumb d-flex align-items-center">
      <Container className="d-flex align-items-center">
        {paths[2]
          ? <>
            <h4><Link to={"/" + paths[1]}>{paths[1]}</Link></h4>
            <ChevronCompactRight />
            <span>{paths[2]}</span>
          </>
          : <h4>{paths[1]}</h4>
        }
      </Container>
    </div>
  );
};

export default Breadcrumb;
