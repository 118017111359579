import { useContext, useEffect, useState } from "react";
import { ItemContext } from "../../../context/ItemContext";
import { Item } from "../../../domain/Item";
import { ItemStock } from "../../../service/ItemStock";
import { ItemProp } from "../../common/item/ItemCard";
import ItemList, { ItemListLoading } from "../../common/item/ItemList";

const FetchFeatureItem = () => {
  const [items, setItems] = useState<Array<Item> | undefined>();
  const { itemType } = useContext(ItemContext);

  useEffect(() => {
    ItemStock.check().getItem(itemType, { tags: [{ value: 'Feature' }] })
      .then(({ items }) => { setItems(items); })
      .catch((e) => { console.log(e); setItems(undefined); });
  }, [itemType]);

  return (
    <>
      <h2>Feature Products</h2>
      {
        items
          ? <ItemList items={items as ItemProp[]} enableLoadMore={false} perPage={8} />
          : <ItemListLoading />
      }
    </>
  );
};

export default FetchFeatureItem;