import { useState } from "react";
import { Image as BSImage, ImageProps } from "react-bootstrap";
import { PATHS } from "../../../config";

const Image = (prop: ImageProps) => {
  const [errored, setErrored] = useState<Boolean>(false);

  const onError = (e: any) => {
    if (!errored) {
      e.currentTarget.src = PATHS.NO_IMAGE;
      setErrored(true);
    }
  };

  return (
    <BSImage {...prop}
      src={prop.src || PATHS.NO_IMAGE}
      alt={prop.alt || "process.diy"}
      onError={onError} />
  );
};

export default Image;
