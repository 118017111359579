/* eslint-disable @typescript-eslint/no-unused-vars */
import { mockData } from "./mock-client";

export type FetchData<T> = Array<T>;
export type FetchError = { statusCode: number, message: string; } | null;
export type FetchResponse<T> = { data: FetchData<T>, error?: FetchError; };
export async function fetchData<T>(url: string): Promise<FetchResponse<T>> {

  await new Promise((resolve) => { setTimeout(() => { resolve("MOCK RESPONSE TIME"); }, 1000); });

  return {
    data: mockData[url],
    error: null
  };
};