const data: any = {
  "blog": [
    {
      "title": "Setting Cura ultimaker and Creality Slicer 4.2 | part1",
      "date": "Oct 8, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/8gX-8SOrNg0/0.jpg",
      "link": "https://youtube.com/watch?v=8gX-8SOrNg0"
    },
    {
      "title": "របៀបកែកូដសម្រាប់ 3D printer - Marlin 1.1.8",
      "date": "Sep 14, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/rLAG06U8RTU/0.jpg",
      "link": "https://youtube.com/watch?v=rLAG06U8RTU"
    },
    {
      "title": "Update Firmware by Hex file - ទាញយក .hex file ចេញពីកម្មវិធី Arduino",
      "date": "Sep 4, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/rLAG06U8RTU/0.jpg",
      "link": "https://youtube.com/watch?v=rLAG06U8RTU"
    },
    {
      "title": "របៀបព្រីន​ 3D ជាមួយកម្មវិធី Cura Ultimaker - Basic",
      "date": "Sep 3, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/gQwtg0Tkd-w/0.jpg",
      "link": "https://youtube.com/watch?v=gQwtg0Tkd-w"
    },
    {
      "title": "First Print 3D with Ender 3pro - Full step",
      "date": "Jun 24, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/fExSMVoOu_Y/0.jpg",
      "link": "https://youtube.com/watch?v=fExSMVoOu_Y"
    },
    {
      "title": "របៀបតេស្តកូដ Arduino ក្នុងកម្មវិធី Proteus",
      "date": "May 12, 2020",
      "thumbnailLink": "https://img.youtube.com/vi/MUFF8HIk1Vk/0.jpg",
      "link": "https://youtube.com/watch?v=MUFF8HIk1Vk"
    }
  ],
  "home-carousel": [
    {
      "title": "3D Printer",
      "title2": "awesome product",
      "content": "Warranty and support by ProcessDIY's technical team. Various 3D Printers brands.",
      "content2": "Delivery in Phnom Penh and all provinces in Cambodia.",
      "imageUrl": "/img1.png",
      "link": "/machine",
      "search": "?category=3D Printer"
    },
    {
      "title": "Creality LD-002R",
      "title2": "LCD Resin 3D Printer",
      "content": "Warranty and support by ProcessDIY's technical team.",
      "content2": "Delivery in Phnom Penh and all provinces in Cambodia.",
      "imageUrl": "/img2.png",
      "link": "/machine",
      "search": "?category=3D Printer"
    },
    {
      "title": "Foam Cutting",
      "title2": "awesome product",
      "content": "Warranty and support by ProcessDIY's technical team.",
      "content2": "Delivery in Phnom Penh and all provinces in Cambodia.",
      "imageUrl": "/img3.png",
      "link": "/machine",
      "search": "?category=Foam Cutting"
    }
  ]
};

data.tag = [
  { type: 'system', value: 'Feature' },
  { type: 'sale', value: 'New' },
  { type: 'item', value: 'Pre-order' },
  { type: 'item', value: 'In Stock' },
  { type: 'item', value: 'Out of Stock' },
  { type: 'item', value: '3D Printer' },
  { type: 'item', value: 'C02 Laser' },
  { type: 'item', value: 'Laser Diode' },
  { type: 'item', value: 'Fiber Making' },
  { type: 'item', value: 'CNC Router' },
  { type: 'item', value: 'Foam Cutting' }
];

data.category = [
  'Tool',
  'Accessory',
  'Other',
  '3D Printer',
  'C02 Laser',
  'Laser Diode',
  'Fiber Making',
  'CNC Router',
  'Foam Cutting'
];


export const mockData = data;