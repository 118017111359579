import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomeCategory.scss";

type HomeCategoryCardProp = {
  title: string;
  subTitle: string;
  imageUrl: string;
  link: string;
};

type HomeCategoryProp = {
  items: Array<HomeCategoryCardProp>;
};

const CategoryCard = (prop: HomeCategoryCardProp) => {
  return <Card className="bg-dark text-white rounded-0 hover-zoom-image" >
    <Card.Img src={prop.imageUrl} alt={prop.imageUrl} />
    <Link to={prop.link}>
      <Card.ImgOverlay>
        <Card.Title>
          {prop.title}
          <span>{prop.subTitle}</span>
        </Card.Title>
      </Card.ImgOverlay>
    </Link>
  </Card>;
};

const HomeCategory = (prop: HomeCategoryProp) => {
  return <Row className="home-category gy-3 gx-3">
    {
      prop.items.map((item, i) =>
        <Col xs={6} md={4} lg={3} key={i}>
          <CategoryCard {...item} />
        </Col>)
    }
  </Row>;
};

export default HomeCategory;