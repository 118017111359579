import { pages } from "../../../App";
import { NAV_BAR_SUB_MENU } from "../../../config";
import { NavBarCustom } from "./Navbar";

const FetchNavBar = () => {
  return (
    <>{
      pages.map((page, i) => {
        page.children = NAV_BAR_SUB_MENU[page.path!!];

        return <NavBarCustom key={i} page={page} />;
      })}
    </>
  );
};

export default FetchNavBar;
