import { Button, Navbar, Offcanvas } from "react-bootstrap";
import { Filter } from "react-bootstrap-icons";
import { useLocation, useSearchParams } from "react-router-dom";

const Panel = ({ children }: any) => {

  const { search } = useLocation();
  const [, setSearchParam] = useSearchParams();

  const clearButtonClick = (e: any) => {
    setSearchParam();
  };

  return (
    <Navbar expand="xl" className="item-filter-panel">
      <Navbar.Toggle aria-controls={`item-filter-offcanvas`} className="bg-white rounded-0" aria-setsize={12}>
        <Filter size={24} /> <span className="d-inline">Filters</span>
      </Navbar.Toggle>
      <Navbar.Offcanvas id={`item-filter-offcanvas`} aria-labelledby={`offcanvasNavbarLabel-expand-xl`} placement="end" >
        <Offcanvas.Header closeButton className="text-secondary">FILTERS</Offcanvas.Header>
        <Offcanvas.Body className="flex-column">
          <div className="d-none d-xl-block">
            <div className="d-flex justify-content-between header">
              <h6 className="align-self-center m-0 pt-1 text-secondary">FILTERS</h6>
              <Button size="sm" variant="link" className="p-0" disabled={!search} onClick={clearButtonClick} >Clear All</Button>
            </div>
            <br />
          </div>
          {children}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
      <Button size="sm" variant="link" className="d-lg-block d-xl-none" disabled={!search} onClick={clearButtonClick}>Clear All</Button>
    </Navbar >
  );
};

export default Panel;
