import { useEffect, useState } from "react";
import { API_PATHS } from "../../../config";
import { Tag } from "../../../domain/Item";
import { FetchResponse, fetchData } from "../../../service/API";
import ItemFilter, { ItemFilterLoading } from "../../common/item-filter/ItemFilter";

const FetchShopFilter = () => {
  const [tags, setTags] = useState<Array<Tag> | undefined>();
  const [categories, setCategories] = useState<Array<string> | undefined>();

  useEffect(() => {
    fetchData<Tag>(API_PATHS.TAG).then((resp) => {
      const tagResponse: FetchResponse<Tag> = resp;
      if (!tagResponse.error)
        setTags(resp.data);
    });

    fetchData<string>(API_PATHS.CATEGORY).then((resp) => {
      const categoryResponse: FetchResponse<string> = resp;
      if (!categoryResponse.error)
        setCategories(categoryResponse.data);
    });
  }, []);

  if (!tags || !categories)
    return <ItemFilterLoading />;

  return (
    <ItemFilter tags={tags} categories={categories} />
  );
};

export default FetchShopFilter;
