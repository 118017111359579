import { DataSnapshot } from "firebase/database";
import { Item } from '../../../domain/Item';
import Database from '../Database';
import firebaseApp from './client';
import { ItemMeta, PathOrganizer } from './path-organizer';

const firebase = firebaseApp().firebase;
export class FirebaseDatabase extends PathOrganizer implements Database {
  private static _instance: FirebaseDatabase;

  private constructor() {
    super();
  }

  public static load = () => {
    if (!FirebaseDatabase._instance) {
      FirebaseDatabase._instance = new FirebaseDatabase();
    }

    return FirebaseDatabase._instance;
  };

  private async getByPath(path: string): Promise<any> {
    return new Promise((resolve, reject) =>
      firebase
        .database()
        .ref(path)
        .on(
          'value', // NEED to verify to prevent much network call
          (snapshot: DataSnapshot) => resolve(snapshot.val())
        )
    );
    // .catch((e: any) => reject(e)));
  }

  public async getOne(meta: ItemMeta): Promise<Item | null> {
    if (meta.path) return this.getByPath(meta.path);

    const { publicPath } = this.getItemPossiblePaths(meta);
    return this.getByPath(publicPath);
  }

  public async getAll(meta: ItemMeta): Promise<Array<Item>> {
    const isRequestIntoOneItemType = meta.itemType;
    const { publicPath } = this.getItemPossiblePaths(meta);
    const responseItem = await this.getByPath(publicPath);
    const items: Item[] = Object.values(responseItem || {});

    if (isRequestIntoOneItemType) return items as unknown as Array<Item>;

    return items.flatMap(Object.values) as unknown as Array<Item>;
  }

  public add(item: Item): Promise<Item> {
    const { id, fullPath } = this.getItemMeta(item);

    const authorId = firebase.auth().currentUser.uid;
    const data: Item = {
      ...item,
      id,
      authorId
    };

    return new Promise(function (resolve, reject) {
      firebase
        .database()
        .ref(fullPath)
        .set(data, (error: any) => {
          if (error) {
            reject(error);
          } else {
            resolve(data);
          }
        });
    });
  }

  public async update(item: Item, oldItem: Item): Promise<Item> {
    const task = this.add({
      ...item,
      updateDate: Date.now(),
      updateById: firebase.auth().currentUser.uid
    });

    if (this.isChangedPathPrefix(item, oldItem)) {
      const meta = this.getItemMeta(oldItem);
      if (meta.fullPath) this.delete(meta.fullPath);
    }

    return task;
  }

  public delete(target: string): Promise<void> {
    const ref = firebase.database().ref(target);
    return ref.remove();
  }
}
