import { Container } from "react-bootstrap";
import "../asset/Style.scss";
import MediaContact from "../component/common/MediaContact";
import Space from "../component/common/Space";
import Image from "../component/common/image/Image";
import { PATHS } from "../config";

type UnderConstructionProp = {
  style?: { [key: string]: string; };
  img?: string;
};

const UnderConstruction = (prop: UnderConstructionProp) => {
  return (
    <div style={{
      width: "100%",
      height: "100%",
      display: "block",
      position: "fixed",
      backgroundColor: "beige",
      ...(prop.style || {})
    }
    }>
      <Container>
        <center>
          <Space />
          <Image src={prop.img || PATHS.LOGO}
            style={{
              width: "150px",
              borderRadius: "50%",
              left: "0",
            }} />
          <Space />
          <h1 className="">
            Coming soon!
          </h1>
          <hr style={{ width: "25%" }} />
          <small>Contact us</small>
          <div style={{
            backgroundColor: "#00000014",
            width: "fit-content",
            padding: "0.5rem 1rem",
            marginTop: "1rem",
            borderRadius: "1.75rem"
          }}>
            <MediaContact />
          </div>
        </center>
      </Container>
    </div>
  );
};

export default UnderConstruction;
