import { Container } from "react-bootstrap";
import {
  Outlet
} from "react-router-dom";
import Footer from "./Footer";
import Header from "./header/Header";

export const PageBodyLayout = ({ children }: any) => {
  return <Container>
    {children}
  </Container>;
};

const MainLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      {children && children}
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;