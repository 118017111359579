import { Price as PriceProp } from "../../../domain/Item";
import "./Item.scss";

const format = (currency: string, value?: number) => value
  ? new Intl.NumberFormat(currency === "usd" ? "en-US" : "km-KH", { style: 'currency', currency })
    .format(value)
  : "";

const Price = (prop: PriceProp) => {

  return (
    <div className={prop.salePrice ? "price with-sale-price" : "price"}>
      <span className="sale-price">{format(prop.currencyCode, prop.salePrice)}</span>
      <span className="base-price">{format(prop.currencyCode, prop.basePrice,)}</span >
    </div >
  );
};

export default Price;
