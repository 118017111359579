import Storage, { StorageFile } from '../Storage';
import firebaseApp from './client';
import { PathOrganizer } from './path-organizer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebase = firebaseApp().firebase;
export class FirebaseStorage extends PathOrganizer implements Storage {
  private static _instance: FirebaseStorage;

  private constructor() {
    super();
  }

  public static load = (): FirebaseStorage => {
    if (!FirebaseStorage._instance) {
      FirebaseStorage._instance = new FirebaseStorage();
    }

    return FirebaseStorage._instance;
  };

  public downloadFiles(urls: Array<string>): Promise<Array<StorageFile>> {
    const tasks: Array<Promise<StorageFile>> = urls.map(
      (url, i) =>
        new Promise((resolve, reject) => {
          fetch(url)
            .then((r) => r.blob())
            .then(async (blob) => {
              resolve({
                dataUrl: await new Promise((r) => {
                  let a = new FileReader();
                  a.onload = r;
                  a.readAsDataURL(blob);
                }).then((e: any) => e.target.result),
                file: new File([blob], i + '', { type: blob.type })
              });
            });
        })
    );

    return Promise.all(tasks);
  }
}
