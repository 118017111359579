import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemContext } from "../../../context/ItemContext";
import { Item } from "../../../domain/Item";
import { ItemStock } from "../../../service/ItemStock";
import ItemView, { ItemViewLoading } from "../../common/item/ItemView";

const FetchItemViewByUrl = () => {
  const [item, setItem] = useState<Item | null>();
  const { id } = useParams();
  const { itemType } = useContext(ItemContext);
  useEffect(() => {
    if (id) {
      ItemStock.check().getItemById(id, itemType).then(rep => setItem(rep));
    }
  }, [id, itemType]);

  if (item === undefined)
    return <ItemViewLoading />;

  if (item === null)
    return <>Not found</>;

  return (
    <ItemView {...item} />
  );
};

export default FetchItemViewByUrl;