import { useEffect, useState } from "react";
import { API_PATHS } from "../../../config";
import { FetchResponse, fetchData } from "../../../service/API";
import HomeCarousel, { HomeCarouselItem } from "./HomeCarousel";

const FetchHomeCarousel = () => {

  const [items, setItems] = useState<Array<HomeCarouselItem>>([]);
  useEffect(() => {
    fetchData<HomeCarouselItem>(API_PATHS.HOME_CAROUSEL).then(resp => {
      const response: FetchResponse<HomeCarouselItem> = resp;
      if (!response.error)
        setItems(response.data);
    });
  }, []);

  return <HomeCarousel items={items} />;
};
export default FetchHomeCarousel;