import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ItemContext } from "../../../context/ItemContext";
import { Item } from "../../../domain/Item";
import { ItemStock } from "../../../service/ItemStock";
import { ItemProp } from "../../common/item/ItemCard";
import ItemList, { ItemListLoading } from "../../common/item/ItemList";

const FetchItem = () => {
  const [items, setItems] = useState<Array<Item> | undefined>();
  const [searchParam] = useSearchParams();
  const { itemType } = useContext(ItemContext);

  useEffect(() => {

    const filter = {
      tag: searchParam.getAll('tag').map(t => t.toLowerCase()),
      category: searchParam.getAll('category').map(t => t.toLowerCase())
    };

    setItems(undefined);

    ItemStock.check().getItem(itemType)
      .then(({ items }) => {

        let result = items;

        if (filter.category.length > 0)
          result = items.filter((item) => (filter.category.some(value => value === item.category.toLowerCase())));

        if (filter.tag.length > 0)
          result = result.filter((item) => item.tags && item.tags.some(tag => filter.tag.includes(tag.value.toLowerCase())));

        setItems(result);

      })
      .catch((e) => { console.log(e); setItems(undefined); });
  }, [itemType, searchParam]);

  if (!items)
    return <><Row className="mt-5"></Row><ItemListLoading /></>;

  return <ItemList items={items as ItemProp[]} metadata={{ xxl: 4, header: true }} />;
};

export default FetchItem;
