import { XOctagon } from "react-bootstrap-icons";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import "./ErrorBoundary.scss";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return <div className="error-boundary-comp">
      <h2>{error.status}</h2>
      <p>{error.statusText}</p>
    </div>;
  }

  return <center>
    <XOctagon size={24} color="red" />
    <p className="p-2 text-secondary">
      Oops! Something went wrong.
    </p>
  </center>;
};

export default ErrorBoundary;