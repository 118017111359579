import { Button, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const ContactBar = () => {
  return <div className="contact-bar">
    <Container>
      <div className="contact-bar-item">
        <LinkContainer to="about-us">
          <Button variant="outline-warning">Contact Us</Button>
        </LinkContainer>
        {/* <MediaContact /> */}
        {/* <Dropdown>
          <Dropdown.Toggle variant="black" id="dropdown-basic">
            <Phone size={28} className="m-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="tel:010399923">010-399-923</Dropdown.Item>
            <Dropdown.Item href="tel:061399922">061-399-922</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </Container>
  </div>;
};
export default ContactBar;