import React from 'react';
import { Col, Dropdown, Row } from "react-bootstrap";
import { Check2, Messenger, PersonGear, Telegram, Telephone, Truck } from 'react-bootstrap-icons';
import ImageGallery from 'react-image-gallery';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config';
import { Item } from "../../../domain/Item";
import { BadgeByTag } from './ItemCard';
import "./ItemView.scss";
import Price from './Price';

const ContactUsToBuy = (prop: Item) => {
  return <Dropdown>
    <Dropdown.Toggle variant="warning" size='lg' id="dropdown-basic">
      Contact us to Buy
    </Dropdown.Toggle>
    <Dropdown.Menu style={{ borderRadius: 0 }}>
      <Dropdown.Item href="tel:061399922"><Telephone /> +(855) 61 399 922</Dropdown.Item>
      <Dropdown.Item href="tel:010399923"><Telephone /> +(855) 10 399 923</Dropdown.Item>
      <Dropdown.Item href="https://t.me/processdiychat"><Telegram /> Telegram</Dropdown.Item>
      <Dropdown.Item href="http://m.me/processdiy" target="_blank"><Messenger /> Messenger</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>;
};

const InformationView = (prop: Item) => {
  let specifications: Array<[string, string]> = [];

  if (prop.specification)
    specifications = prop.specification.split('\n').map((spec, i) => {
      const [k, v] = spec.split(':');
      return [k, v];
    });

  return <>
    <h1>{prop.title}</h1>
    <Price {...prop.price} />
    <p className='mb-4' style={{ fontWeight: 300, whiteSpace: "pre-line" }}>{prop.description}</p>
    <p className='text-secondary'>
      <span>CATEGORY: </span>
      <span className='text-capitalize'>{prop.category}</span>
    </p>
    <ContactUsToBuy {...prop} />
    <hr />
    <h5 className='mb-3 text-uppercase'>Specification</h5>
    <Row className='gy-2'>
      {
        specifications.length === 0 && <Col xs={12} className='text-secondary'>
          No information
        </Col>
      }
      {specifications.map(([k, v], i) => {
        return <React.Fragment key={i}>
          <Col xs={4} className={(i % 2 === 0 ? 'bg-light' : '')} style={{ fontWeight: 300 }}>
            <span className='text-capitalize'>{k}</span>
          </Col>
          <Col xs={8} className={(i % 2 === 0 ? 'bg-light' : '')} style={{ fontWeight: 300 }}>
            <span>{v}</span>
          </Col>
        </React.Fragment>;
      })}
    </Row>
    <hr />
    <h5 className='mb-3 text-uppercase'><Truck className='align-top' /> Delivery</h5>
    <p>
      Delivery to all locations in Cambodia OR pick up at our <Link to={PATHS.ABOUT_US + "#contact-us"} className='text-secondary'>Phnom Penh office</Link>.
    </p>
    <br />
    <h5 className='mb-3 text-uppercase'><PersonGear className='align-top' /> Support</h5>
    <p>
      <Check2 /> <span className='align-middle'>Hardware setup consult</span>
    </p>
    <p>
      <Check2 /> <span className='align-middle'>Software usage consult</span>
    </p>
    <p>
      <Check2 /> <span className='align-middle'>Supported tutorials and blogs</span>
    </p>
  </>;
};

const ItemView = (prop: Item) => {

  let images: Array<{ original: string, thumbnail: string; }> = [];
  if (prop.imageUrls)
    images = prop.imageUrls.map((url, i) => ({ original: url, thumbnail: url, }));

  return (
    <Row className="item-view gy-5 gx-5">
      <Col xs={12} lg={6}>
        <div className='tag-container'>
          {prop.tags && <BadgeByTag tags={prop.tags} />}
        </div>
        <ImageGallery
          items={images}
          showPlayButton={false}
          showIndex
          showFullscreenButton={false}
        />
      </Col>
      <Col xs={12} lg={6}>
        <InformationView {...prop} />
      </Col>
    </Row>
  );
};

export const ItemViewLoading = () => {
  return <Row className="item-view gy-5 gx-5">
    <Col xs={12} lg={6}>
      <Skeleton height={250} />
    </Col>
    <Col xs={12} lg={6}>
      <Skeleton height={350} />
    </Col>
  </Row>;
};

export default ItemView;