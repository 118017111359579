import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { useSearchParams } from "react-router-dom";
import { Tag } from "../../../domain/Item";
import "./ItemFilter.scss";
import Panel from "./Panel";

type ItemFilterProp = {
  categories?: Array<string>,
  tags?: Array<Tag>;
};

type FilterState = {
  [key: string]: { [k: string]: boolean; };
};
const ItemFilter = (prop: ItemFilterProp) => {
  const [filter, setFilter] = useState<FilterState>({ tag: {}, category: {} });
  const [searchParam, setSearchParam] = useSearchParams();

  useEffect(() => {
    const tag = searchParam.getAll("tag"),
      category = searchParam.getAll("category");

    const latestFilter: any = { tag: {}, category: {} };
    tag.forEach((v) => { latestFilter.tag[v] = true; });
    category.forEach((v) => { latestFilter.category[v] = true; });

    setFilter(latestFilter);

  }, [searchParam]);
  const filterOnChange = (key: string, value: string) => (e: any) => {
    if (e.target?.checked) searchParam.append(key, value);
    else {
      const tags = searchParam.getAll(key).filter(v => v !== value);
      searchParam.delete(key);
      for (const tag of tags) searchParam.append(key, tag);
    }
    setSearchParam(searchParam);
  };
  return (
    <Panel>
      <div>
        <p className="text-secondary">Category</p>
        {prop.categories?.map((category, i) => {

          return <Form.Group key={i}> <Form.Check
            key={i}
            checked={!!filter.category[category]}
            type="checkbox"
            id={`category-checkbox-${i}`}
            label={category}
            data-testid="category"
            onChange={filterOnChange("category", category)}
          /></Form.Group>;
        })}
        <hr />
        <p className="text-secondary">Tag</p>
        {prop.tags?.map((tag, i) => {
          return <Form.Check
            key={i}
            checked={!!filter.tag[tag.value]}
            type="checkbox"
            id={`tag-checkbox-${i}`}
            data-testid="tag"
            label={tag.value}
            onChange={filterOnChange("tag", tag.value)}
          />;
        })}
      </div>
    </Panel>
  );
};


export const ItemFilterLoading = () => {
  return (
    <Panel >
      <div>
        <Skeleton count={5} height={30} />
      </div>
    </Panel>
  );
};


export default ItemFilter;;