import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { MASTER_DATA } from "../../../config";
import Space from "../Space";
import ItemCard, { ItemProp } from "./ItemCard";
import LoadMoreButton from "./LoadMoreButton";

type ItemListProp = {
  items: Array<ItemProp>;
  perPage?: number;
  enableLoadMore?: boolean;
  metadata?: {
    header?: boolean;
    xxl?: 3 | 4;
  };
};

const ItemList = (prop: ItemListProp) => {
  const perPage = prop.perPage || MASTER_DATA.DEFAULT_ITEM_PER_PAGE;

  const [thresholdIndex, setThresholdIndex] = useState<number>(perPage);
  const [items, setItems] = useState(prop.items.filter((_, i) => i < thresholdIndex));

  useEffect(() => {
    setItems(
      prop.items.filter((_, i) => i < thresholdIndex)
    );
  }, [prop.items, thresholdIndex]);

  const enableLoadMoreButton = prop.enableLoadMore === undefined || prop.enableLoadMore;

  return (
    <Row className="gy-4 gx-3">
      {
        prop.metadata?.header && <Col xs={12}>
          <p className="text-secondary text-end mt-2">{`${items.length} Item${items.length > 1 ? "s" : ""} Found`}</p>
        </Col>
      }
      {
        items.map((item, i) =>
          <Col xs={12} md={6} lg={4} xxl={prop.metadata?.xxl ? prop.metadata.xxl : 3} key={i}>
            <Container>
              <ItemCard {...item} key={i} enableBadge />
            </Container>
          </Col>)
      }
      {enableLoadMoreButton && <LoadMoreButton
        total={prop.items.length}
        perPage={perPage}
        currentItem={items.length}
        setThresholdIndex={setThresholdIndex} />}
      <Space />
    </Row>
  );
};

export const ItemListLoading = () => {
  return (
    <>
      <Row className="gy-4 gx-3">
        <Col xs={12} md={6} lg={4}>
          <Container>
            <Skeleton height={250} />
          </Container>
        </Col>
        <Space />
      </Row>

    </>
  );
};

export default ItemList;
