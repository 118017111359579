import Space from "../../component/common/Space";
import FetchHomeBlogSection from "../../component/container/home-blog-section/FetchHomeBlogSection";
import FetchHomeCarousel from "../../component/container/home-carousel/FetchHomeCarousel";
import FetchHomeCategory from "../../component/container/home-category/FetchHomeCategory";
import FetchHomeFeatureItem from "../../component/container/home-feature-item/FetchHomeFeatureItem";
import { PageBodyLayout } from "../../component/layout/Layout";

const Home = () => {
  return <>
    <FetchHomeCarousel />
    <PageBodyLayout>
      <Space />
      <FetchHomeCategory />
      <Space />
      <FetchHomeFeatureItem />
    </PageBodyLayout>
    <FetchHomeBlogSection />
  </>;
};

export default Home;