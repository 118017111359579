import { Item, ItemStatus, ItemType } from '../../../domain/Item';
import { StorageFile } from '../Storage';
import firebaseApp from './client';

const firebase = firebaseApp().firebase;

const PREFIX = {
  [ItemStatus.DRAFT]: 'private',
  [ItemStatus.PUBLISH]: 'public'
};

export type ItemMeta = {
  id: string;
  path: string;
  fullPath: string;
  itemType?: ItemType;
};
export type PossiblePath = { privatePath: string; publicPath: string; };
export class PathOrganizer {
  public getItemMeta(item: Item): ItemMeta {
    const path = PREFIX[item.status] + '/item/' + item.itemType;
    const id = item.id || firebase.database().ref(path).push().key;
    const fullPath = path + '/' + id;

    return { id, path, fullPath };
  }

  protected getItemFilePath(
    itemPath: string,
    files: Array<StorageFile>
  ): Array<string> {
    return files.map((f, i) => `${itemPath}/${i + 1}-${f.file?.name}`);
  }

  protected getItemPossiblePaths(meta: ItemMeta): PossiblePath {
    let privatePath = `${PREFIX[ItemStatus.DRAFT]}/item`,
      publicPath = `${PREFIX[ItemStatus.PUBLISH]}/item`;

    if (meta.itemType) {
      privatePath += `/${meta.itemType}`;
      publicPath += `/${meta.itemType}`;
    }

    if (meta.itemType && meta.id) {
      privatePath += `/${meta.id}`;
      publicPath += `/${meta.id}`;
    }

    return { privatePath, publicPath };
  }

  protected isChangedPathPrefix(newItem: Item, oldItem: Item): boolean {
    return newItem.status !== oldItem?.status;
  }
}
