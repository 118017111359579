import HomeCategory from "./HomeCategory";

const FetchHomeCategory = () => {

  const categories = [
    {
      title: "3D Printer",
      subTitle: "Machine",
      imageUrl: "img1.png",
      link: "/machine?category=3D Printer"
    },
    {
      title: "Foam Cutting",
      subTitle: "Machine",
      imageUrl: "img3.png",
      link: "/machine?category=Foam Cutting"
    },
    {
      title: "ProcessDIY Product",
      subTitle: "Product",
      imageUrl: "img5.png",
      link: "/product"
    },
    {
      title: "Accessory",
      subTitle: "Shop",
      imageUrl: "img4.png",
      link: "/shop"
    }
  ];

  return (
    <HomeCategory items={categories} />
  );
};

export default FetchHomeCategory;
