import { Container, Nav, Navbar } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { PATHS } from "../../../config";
import ScrollToTop from "../../common/ScrollToTop";
import Image from "../../common/image/Image";
import ContactBar from "./ContactBar";
import FetchNavBar from "./FetchNavbar";
import "./Header.scss";

const NavMenu = () => {
  return <Navbar expand="lg" collapseOnSelect>
    <Container>
      <Navbar.Brand href="/">
        <Image src={PATHS.LOGO_OUTLINE} />
        <p>
          Workshop • 3D Printer • CNC Builder
        </p>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" children={
        <>
          <List size={28} />
          <span>MENU</span>
        </>
      } />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end flex-grow-1 column-gap-2 gap-0">
          <FetchNavBar />
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
};
const Header = () => {
  return <>
    <ScrollToTop />
    <ContactBar />
    <NavMenu />
  </>;
};

export default Header;