
const Space = () => {
  let height = "40px";

  return (
    <div style={{ width: "100%", display: "block", height }}>
    </div>
  );
};

export default Space;
