import { useEffect, useState } from "react";
import { API_PATHS } from "../../../config";
import { FetchResponse, fetchData } from "../../../service/API";
import HomeBlogSection, { BlogCardProp } from "./HomeBlogSection";

const FetchHomeBlogSection = () => {

  const [items, setItems] = useState<Array<BlogCardProp> | undefined>([]);
  useEffect(() => {
    fetchData<BlogCardProp>(API_PATHS.BLOG).then((resp) => {
      const response: FetchResponse<BlogCardProp> = resp;
      if (!response.error)
        setItems(response.data);
    });
  }, []);

  return (<HomeBlogSection items={items} />);
};

export default FetchHomeBlogSection;
