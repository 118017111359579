import { Container } from "react-bootstrap";
import Space from "../../component/common/Space";
import Breadcrumb from "../../component/common/breadcrumb/Breadcrumb";
import FetchItemView from "../../component/container/item-view/FetchItemView";
import FetchRelatedItemList from "../../component/container/item-view/FetchRelatedItemList";

const ItemPage = () => {
  return (
    <>
      <Breadcrumb />
      <Space />
      <Container>
        <FetchItemView />
        <Space />
        <Space />
        <FetchRelatedItemList />
        <Space />
      </Container>
    </>
  );
};
export default ItemPage;
