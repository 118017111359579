import { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RouteObject } from "react-router-dom";

const NavDropdownCustom = ({ page }: any) => {
  const [show, setShow] = useState(false);
  const showDropdown = (e: any) => setShow(!show);
  const hideDropdown = (e: any) => setShow(false);

  return <NavDropdown
    title={page.id}
    id="navbarScrollingDropdown"
    show={show}
    onMouseEnter={showDropdown}
    onMouseLeave={hideDropdown}
    onSelect={hideDropdown}
  > {
      page.children?.map((subPage: any, i: number) =>
        <LinkContainer
          key={i}
          to={{ pathname: subPage.pathname, search: subPage.search }}
          activeClassName="text-dark"
        >
          <NavDropdown.Item>{subPage.id}</NavDropdown.Item>
        </LinkContainer>
      )
    }
  </NavDropdown>;
};

export const NavBarCustom = ({ page }: { page: RouteObject; }) => {
  if (page.children)
    return <NavDropdownCustom page={page} />;

  return <LinkContainer to={page.path!!} activeClassName="text-dark">
    <Nav.Link>{page.id}</Nav.Link>
  </LinkContainer>;
};