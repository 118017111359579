import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PATHS } from "../../config";
import MediaContact from "../common/MediaContact";
import Space from "../common/Space";
import Image from "../common/image/Image";

const Footer = () => {
  return (
    <div className="bg-black text-secondary">
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <Space />
            <Image src={PATHS.LOGO} width={"57px"} />
            <Space />
            <p>
              Address: <br />
              Wat Prachum Sako Rd, Sangkat Chroy Chang Va, Phnom Penh, Cambodia 12110
            </p>
            <p>
              Contact: <br />
              +(855) 61 399 922<br />
              +(855) 10 399 923<br />
              admin@processdiy.com<br />
            </p>
            <MediaContact />
          </Col>
          <Col xs={12} md={4}>
            <Space />
            <h5>FOLLOW US</h5>
            <div
              className="fb-page"
              data-href="https://web.facebook.com/processdiy"
              data-width="380"
              data-hide-cover="false"
              data-show-facepile="false"
            ></div>
            <div className="d-flex flex-column mt-3">
              <div className="pt-2 pb-2 d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <Link to={PATHS.HOME} className="text-secondary">Home</Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Link to={PATHS.PRODUCT} className="text-secondary">Our Products</Link>
                </div>
              </div>
              <div className="pt-2 pb-2 d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <Link to={PATHS.THREE_D_PRINTER} className="text-secondary">3D Printers</Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Link to={PATHS.ABOUT_US} className="text-secondary">About Us</Link>
                </div>
              </div>
              <div className="pt-2 pb-2 bd-highlight">
                <Link to={PATHS.MACHINE} className="text-secondary">Machines</Link>
              </div>
              <div className="pt-2 pb-2 bd-highlight">
                <Link to={PATHS.SHOP} className="text-secondary">Shop</Link>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <hr />
            <p className="pt-4">
              {new Date().getFullYear()} ProcessDIY. All rights reserved.
            </p>
            <Space />
            <Space />
          </Col>
        </Row >
      </Container>
    </div>
  );
};

export default Footer;
