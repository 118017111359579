import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Item, Tag } from "../../../domain/Item";
import Image from "../image/Image";
import Price from "./Price";

export const BadgeByTag = ({ tags }: { tags: Array<Tag>; }) => {

  const otherTags: Array<Tag> = [];

  if (!tags.length)
    return <></>;

  return <>
    <Stack gap={1} className="sale-tag"> {
      tags.filter((tag, i) => {

        if (tag.type === 'system')
          return false;

        if (tag.type === 'sale')
          return true;

        otherTags.push(tag);
        return false;
      }).map((tag, i) => <div key={i}>{tag.value}</div>)
    }
    </Stack>
    <div className="tag">
      {otherTags.map((tag, i) => <div key={i} >{tag.value}</div >)}
    </div>
  </>;
};

export type ItemProp = Item & {
  enableBadge?: boolean;
};

const ItemCard = (prop: ItemProp) => {

  const itemLink = "/" + prop.itemType + "/" + prop.category + "/" + prop.id;

  return (
    <Link to={itemLink} className="item-card">
      <div>
        <div className="thumbnail d-flex justify-content-center">
          <Image
            className="align-self-center"
            src={prop.imageUrls && prop.imageUrls[0]}
            alt={prop.title}
          />
        </div>
        {prop.enableBadge && prop.tags && <BadgeByTag tags={prop.tags} />}
        <div className="pb-3">
          <div className="title">{prop.title}</div>
          <Price {...prop.price} />
        </div>
      </div>
    </Link >
  );
};

export default ItemCard;
