import { Button, Col, Container, Row } from "react-bootstrap";
import { ChevronDoubleRight, Clock, ClockHistory, SearchHeart, Stars, Youtube } from "react-bootstrap-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Space from "../../common/Space";
import Image from "../../common/image/Image";
import { ItemListLoading } from "../../common/item/ItemList";
import "./HomeBlogSection.scss";

export type BlogCardProp = {
  title: string;
  date: string;
  link: string;
  thumbnailLink: string;
};

type HomeBlogSectionProp = {
  items: Array<BlogCardProp> | undefined;
};

const carouselResponsive = {
  xs: { breakpoint: { min: 0, max: 576, }, items: 1 },
  sm: { breakpoint: { min: 576, max: 768, }, items: 2 },
  md: { breakpoint: { min: 768, max: 992, }, items: 3 },
  lg: { breakpoint: { min: 992, max: 1200, }, items: 4 },
  xl: { breakpoint: { min: 1200, max: 1400, }, items: 5 },
  xxl: { breakpoint: { min: 1400, max: 4000, }, items: 6 },
};

const BlogCardMeta = () => {
  return <Youtube size={32} color="red" className="blog-resource-tag" />;
};

const BlogCard = (prop: BlogCardProp) => {
  return (
    <Container className="hover-zoom-image">
      <div className="blog-card">
        <BlogCardMeta />
        <div className="overlay"></div>
        <Image src={prop.thumbnailLink} alt={prop.title} />
        <Link to={prop.link} target="_blank" rel="noopener noreferrer">
          <div className="title">
            <p>{prop.title}</p>
            <hr />
            <Clock size={18} color="grey" /><span>{prop.date}</span>
          </div>
        </Link>
      </div>
    </Container >
  );
};

const BlogIntro = () => {
  return <Row className="gy-3 blog-intro">
    <Col sx={12} lg={6}>
      <Image src="/aboutus1.png" alt="Learn from sharing" width="100%" />
    </Col>
    <Col sx={12} lg={6}>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="align-self-center"><SearchHeart size={32} /></div>
          <div className="align-self-center">
            <p>We started with ♡ and found that it is <span>our passion</span></p>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="align-self-center"><ClockHistory size={32} /></div>
          <div className="align-self-center">
            <p>For years, we have learnt a lots from <span>R&D</span></p>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="align-self-center"><Stars size={32} /></div>
          <div className="align-self-center">
            <p>We are really glad to share the <span>resources, tutorials, and tools</span> for everyone having same interest</p>
          </div>
        </div>
      </div>
      <p>Check out our blogs and all the resources</p>
      <a href="https://www.youtube.com/@processdiy" target="_blank" rel="noreferrer">
        <Button variant="warning" size="lg">
          See more
          <ChevronDoubleRight />
        </Button>
      </a>
    </Col>
  </Row>;
};

const HomeBlogSection = (prop: HomeBlogSectionProp) => {
  return (<div className="home-blog-section">
    <Container>
      <Space />
      <h2>Explore Our Resources</h2>
    </Container>
    <Container>
      <BlogIntro />
      <Space />
      <h5>Hight Light Tutorials and Blogs</h5>
      <br />
    </Container>
    <Carousel responsive={carouselResponsive}>
      {
        prop.items
          ? prop.items.map((item, i) => <BlogCard key={i} {...item} />)
          : <ItemListLoading />
      }
    </Carousel>
    <Space />
    <Space />
  </div>
  );
};

export default HomeBlogSection;

