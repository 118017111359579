import Ajv, { JTDSchemaType } from 'ajv/dist/jtd';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { Item, ItemStatus, ItemType } from '../domain/Item';
import { filterTag } from "./Helper";
import Database from './driver/Database';
import Storage, { StorageFile } from './driver/Storage';
import { FirebaseDatabase } from './driver/firebase/database';
import { FirebaseStorage } from './driver/firebase/storage';
import { ItemOverview } from './types/ItemOverview';

export type GetItemResponse = {
  items: Array<Item>;
  errorMessage: string;
};
export type Filter = {
  [k: string]: any;
};

const ajv = new Ajv({
  allErrors: true
});

const itemSchema: JTDSchemaType<Item> = {
  properties: {
    id: { type: 'string' },
    title: { type: 'string' },
    description: { type: 'string' },
    itemType: { enum: ['shop', 'machine', 'product'] },
    category: { type: 'string' },
    authorId: { type: 'string' },
    createDate: { type: 'float64' }, // TODO change to timestamp
    price: {
      properties: {
        basePrice: { type: 'float32' },
        currencyCode: { enum: ['usd', 'khr'] }
      },
      optionalProperties: {
        salePrice: { type: 'float32' }
      }
    },
    status: { enum: ['publish' as ItemStatus, 'draft' as ItemStatus] } // TODO to proper define enum
  },
  optionalProperties: {
    tags: {
      elements: {
        properties: {
          type: { enum: ['system', 'item', 'sale'] },
          value: { type: 'string' }
        }
      }
    },
    imageUrls: { elements: { type: 'string' } },
    videoUrls: { elements: { type: 'string' } },
    specification: { type: 'string' },
    updateById: { type: 'string' },
    updateDate: { type: 'float64' } // TODO change to use timestamp, int32 is not supported
  }
};

const itemListSchema: JTDSchemaType<Array<Item>> = {
  elements: {
    ...itemSchema
  }
};

const itemValidate = ajv.compile(itemSchema);
const itemListValidate = ajv.compile(itemListSchema);

export class ItemStock {
  db: Database;

  storage: Storage;

  private static _instance: ItemStock;

  private constructor(db: Database, storage: Storage) {
    this.db = db;
    this.storage = storage;
  }

  static validate(data: any) {
    if (Array.isArray(data)) {
      if (itemListValidate(data)) return null;
      else return itemListValidate.errors;
    } else {
      if (itemValidate(data)) return null;
      else return itemValidate.errors;
    }
  }

  static removeInvalidItem(
    items: Array<any>,
    errors: Array<any>
  ): GetItemResponse {
    let errorMessage = '';
    for (const e of errors) {
      const [idx] = e.instancePath.match('[0-9]+');
      if (idx) {
        if (idx in items) {
          errorMessage +=
            'ID:' +
            items[idx].id +
            ':' +
            e.instancePath +
            ':' +
            e.message +
            ';';
          items.splice(idx, 1);
        }
      }
    }

    return { items, errorMessage };
  }

  public static check = (): ItemStock => {
    if (!ItemStock._instance) {
      ItemStock._instance = new ItemStock(
        FirebaseDatabase.load(),
        FirebaseStorage.load()
      );
    }

    return ItemStock._instance;
  };

  public async getItemById(
    id: string,
    itemType: ItemType
  ): Promise<Item | null> {
    const item = await this.db.getOne({ id, itemType });
    const errors = ItemStock.validate(item);
    if (errors) {
      let msg = '';
      for (const e of errors) msg += e.instancePath + ':' + e.message + ';';
      throw Error(msg);
    }

    return item;
  }

  public getItem = async (itemType: ItemType, filter?: Filter): Promise<GetItemResponse> => {
    const items = await this.db.getAll({ itemType });
    const errors = ItemStock.validate(items);
    if (errors) return ItemStock.removeInvalidItem(items, errors);

    if (filter?.tags)
      return { items: filterTag<Item>(items, filter.tags), errorMessage: '' };

    return { items, errorMessage: '' };
  };

  public downloadAttachedFiles(
    urls?: Array<string>
  ): Promise<Array<StorageFile>> {
    if (!urls || urls.length === 0) return Promise.all([]);

    return this.storage.downloadFiles(urls);
  }

  public async getItemOverview(): Promise<ItemOverview> {
    const items = await this.db.getAll({});

    let publishedItem = 0;
    let saleTagItem = 0;
    let category: any = {
      shop: { publish: 0, draft: 0 },
      machine: { publish: 0, draft: 0 },
      product: { publish: 0, draft: 0 }
    };
    items.forEach((item) => {
      const c = item.itemType ? item.itemType : 'other';
      if (!(c in category)) category[c] = { publish: 0, draft: 0 };
      saleTagItem +=
        item.tags && item.tags.some((tag) => tag.type === 'sale') ? 1 : 0;

      if (item.status === ItemStatus.PUBLISH) {
        publishedItem += 1;
        category[c].publish += 1;
      } else category[c].draft += 1;
    });

    return {
      publishedItem,
      saleTagItem,
      category
    };
  }
}
