import { Facebook, Instagram, Telegram, Youtube } from "react-bootstrap-icons";

const MediaContact = ({ color }: { color?: string; }) => {
  const c = color || 'grey';
  return (
    <>
      <a href="https://web.facebook.com/processdiy/" target="_blank" title="https://web.facebook.com/processdiy" rel="noopener noreferrer">
        <Facebook size={28} className="m-2" color={c} />
      </a>
      <a href="https://www.instagram.com/processdiy" target="_blank" title="Instagram" rel="noopener noreferrer">
        <Instagram size={28} className="m-2" color={c} />
      </a>
      <a href="https://www.youtube.com/channel/UC4U0GqKbgf5Onu3Cr7Djopg" target="_blank" rel="noopener noreferrer" title="Youtube">
        <Youtube size={30} className="m-2" color={c} />
      </a>
      <a target="_blank" href="https://t.me/processdiychat" rel="noopener noreferrer">
        <Telegram size={28} className="m-2" color={c} />
      </a>
    </>
  );
};
export default MediaContact;
