import {
  RouteObject,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import "./asset/Style.scss";
import ErrorBoundary from "./component/common/error-boundary/ErrorBoundary";
import MainLayout from "./component/layout/Layout";
import { PATHS } from "./config";
import { ItemProvider } from "./context/ItemContext";
import AboutUs from "./page/about-us/AboutUs";
import Home from "./page/home/Home";
import ItemPage from "./page/item-view/ItemView";
import Item from "./page/item/Item";

export const pages: RouteObject[] = [
  {
    id: "Home",
    path: PATHS.HOME,
    element: <Home />
  },
  {
    id: "Shop",
    path: PATHS.SHOP,
    element: <ItemProvider itemType={'shop'} />,
    children: [
      {
        path: "",
        element: <Item />
      },
      {
        id: "Shop Item",
        path: ":category/:id",
        element: <ItemPage />,
      }
    ]
  },
  {
    id: "Machine",
    path: PATHS.MACHINE,
    element: <ItemProvider itemType={'machine'} />,
    children: [
      {
        path: "",
        element: <Item />
      },
      {
        id: "Machine Item",
        path: ":category/:id",
        element: <ItemPage />,
      }
    ]
  },
  {
    id: "Product",
    path: PATHS.PRODUCT,
    element: <ItemProvider itemType={'product'} />,
    children: [
      {
        path: "",
        element: <Item />
      },
      {
        id: "Product Item",
        path: ":category/:id",
        element: <ItemPage />,
      }
    ]
  },
  // {
  //   id: "Download",
  //   path: PATHS.DOWNLOAD,
  //   element: <UnderConstructionBody />,
  // },
  {
    id: "About Us",
    path: PATHS.ABOUT_US,
    element: <AboutUs />,
  }
],
  router = createBrowserRouter([
    {
      path: PATHS.HOME,
      element: <MainLayout />,
      errorElement: <MainLayout><ErrorBoundary /></MainLayout>,
      children: pages
    },
  ]);

const App = () => {
  return (<>
    <RouterProvider router={router} />
  </>);
};

export default App;
