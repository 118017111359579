import { Button, Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { LinkContainer } from "react-router-bootstrap";
import Image from "../../common/image/Image";
import "./HomeCarousel.scss";

export type HomeCarouselItem = {
  title: string;
  title2?: string;
  content: string;
  content2?: string;
  imageUrl: string;
  link: string;
  search: string;
};
type HomeCarouselProp = {
  items: HomeCarouselItem[];
};

const HomeCarousel = (prop: HomeCarouselProp) => {
  return <>
    {prop.items.length <= 0 && <Skeleton height={475} width={"100%"} />}
    <Carousel id="home-carousel" fade controls={false}>
      {
        prop.items.map((item, i) => <Carousel.Item key={i}>
          <div className="mask flex-center">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-12 order-md-1 order-2 p-4">
                  <Image src={item.imageUrl} className="shadow-image" alt="slide" width={1000} />
                  <h4><span>{item.title}</span>{item.title2 && <><br />{item.title2}</>}</h4>
                  <p>{item.content}{item.content2 && <><br />{item.content2}</>}</p>
                  <LinkContainer to={{ pathname: item.link, search: item.search }}>
                    <Button size="lg" variant="warning">
                      Buy now
                    </Button>
                  </LinkContainer>
                </div>
                <div className="col-md-5 col-12 order-md-2 order-1">
                  <Image src={item.imageUrl} className="mx-auto" alt="slide" width={500} />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item >)
      }
    </Carousel >
  </>;
};
export default HomeCarousel;