import { Col, Container, Row } from "react-bootstrap";
import Space from "../../component/common/Space";
import Breadcrumb from "../../component/common/breadcrumb/Breadcrumb";
import FetchItem from "../../component/container/item/FetchItem";
import FetchItemFilter from "../../component/container/item/FetchItemFilter";

const Item = () => {
  return (
    <>
      <Breadcrumb />
      <Container>
        <Space />
        <Row className="gy-1 gx-3">
          <Col xs={12} xl={2}>
            <FetchItemFilter />
          </Col>
          <Col xs={12} xl={10}>
            {/* TODO */}
            {/* <Badge pill bg="light" style={{ fontWeight: 300 }} className="m-1 text-dark border">3D Printer <XCircle /></Badge> */}
            <FetchItem />
          </Col>
        </Row>
      </Container>
      <Space />
    </>
  );
};

export default Item;;
