import { Tag } from "../domain/Item";

export const sort = <T>(
  array: Array<any>,
  k: keyof T,
  order?: 'asc' | 'desc'
) => array.sort((a, b) => (order === 'asc' ? 1 : -1) * (a[k] - b[k]));

export const filter = <T>(
  array: Array<any>,
  target: { [key: string]: any; }
): T[] => array.filter(item => {
  for (const [k, targetValue] of Object.entries(target))
    if (item[k] === targetValue) return true;
  return false;
});

export const filterTag = <T>(
  array: Array<any>,
  tags: Array<Tag>
): T[] => array.filter(item =>
  tags.some(tag =>
    item.tags?.some((t: Tag) => t.value === tag.value)
  ));
