import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

let firebaseConfig = {};
try {
  firebaseConfig = JSON.parse(
    process.env.REACT_APP_FIREBASE_CONFIG_JSON_STRING || ''
  );
} catch (e) {
  console.log(e);
}

// Initialize Firebase
let instance: any;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }

  return null;
}
