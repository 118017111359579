import { Col, Container, Row } from "react-bootstrap";
import MediaContact from "../../component/common/MediaContact";
import "./AboutUs.scss";

const AboutUs = () => {
  return <Container className="mt-5 pb-5"
    style={{
      overflowWrap: "break-word",
    }}>
    <Row>
      {/* <Col
        xs={12}
        style={{
          textAlign: "center",
          fontWeight: 500,
          fontSize: "2em",
          color: "grey",
          marginBottom: "1em",
        }}
      >
        Contact ProcessDIY
        <small
          style={{
            fontWeight: 300,
            fontSize: "16px",
            display: "block",
          }}
        >
          We glad to hear from you. <br /> Feel free to contact us or visit
          our office in Phnom Penh.
        </small>
        <center>
          <hr style={{ width: "150px", marginTop: "1em" }} />
        </center>
      </Col> */}
      <Col md={6} style={{ padding: "15px" }}>
        <Row>
          <Col xs={12}>
            <h2>Contact Us</h2>
          </Col>
          <Col xs={12}>
            <h6>Phone</h6>
          </Col>
          <Col xs={4}>Sell:</Col>
          <Col xs={8}>+855 (0) 61 399 922</Col>
          <Col xs={4}>Customer service:</Col>
          <Col xs={8}>+855 (0) 10 399 923</Col>
        </Row>
        {/* <br /> */}
        {/* <Row>
          <Col xs={12}>
            <strong>Telegram:</strong>
          </Col>
          <Col xs={4}>Sell:</Col>
          <Col xs={8}>+855 (0) 61 399 922</Col>
          <Col xs={4}>Customer service:</Col>
          <Col xs={8}>+855 (0) 10 399 923</Col>
        </Row>*/}
        <br />
        <Row>
          <Col xs={12}>
            <h6>Other channels</h6>
            <MediaContact color="black" />
            <br />
          </Col>
          {/* <Col xs={4}>Website:</Col>
          <Col xs={8}>
            <a target="_blank" href="www.processdiy.com">
              www.processdiy.com
            </a>
          </Col>
          <Col xs={4}>Facebook:</Col>
          <Col xs={8}>
            <a
              target="_blank"
              href="https://web.facebook.com/processdiy/"
              rel="noopener noreferrer"
            >
              https://web.facebook.com/processdiy/
            </a>
          </Col>
          <Col xs={4}>Youtube:</Col>
          <Col xs={8}>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC4U0GqKbgf5Onu3Cr7Djopg"
              rel="noopener noreferrer"
            >
              https://www.youtube.com/channel/UC4U0GqKbgf5Onu3Cr7Djopg
            </a>
          </Col>
          <Col xs={4}>Instagram:</Col>
          <Col xs={8}>
            <a
              target="_blank"
              href="https://www.instagram.com/processdiy"
              rel="noopener noreferrer"
            >
              https://www.instagram.com/processdiy
            </a>
          </Col>
          <Col xs={4}>Telegram:</Col>
          <Col xs={8}>
            <a
              target="_blank"
              href="https://t.me/processdiy"
              rel="noopener noreferrer"
            >
              https://t.me/processdiy
            </a>
          </Col> */}
        </Row>
        {/* <br />
          <br /> */}
      </Col>
      <Col md={6}>
        <div className="contact-use-image-map">
          {/* <a
              href="https://www.google.com/maps/place/ProcessDIY/@11.5741246,104.926555,15z/data=!4m5!3m4!1s0x310951bd0180f97b:0xf4f6d3868fc861ac!8m2!3d11.5752488!4d104.9342079?hl=en"
              target="_blank"
              title="Wat Prachum Sako Rd, Sangkat Chroy Chang Va, Phnom Penh, Cambodia
              12110"
              rel="noopener noreferrer"
              className="contact-use-image-map"
            > */}
          <div className="image-div">
            <img
              src={require("../../asset/img/location.png")}
              alt="Wat Prachum Sako Rd, Sangkat Chroy Chang Va, Phnom Penh, Cambodia
                  12110"
              width="100%"
              className="image"
            />
            <a
              href="https://www.google.com/maps/place/ProcessDIY/@11.5741246,104.926555,15z/data=!4m5!3m4!1s0x310951bd0180f97b:0xf4f6d3868fc861ac!8m2!3d11.5752488!4d104.9342079?hl=en"
              target="_blank"
              title="Wat Prachum Sako Rd, Sangkat Chroy Chang Va, Phnom Penh, Cambodia
              12110"
              rel="noopener noreferrer"
              className="contact-use-image-map"
            >
              <div className="middle">
                <div className="text">View map</div>
              </div>
            </a>
          </div>
          {/* </a> */}
        </div>
        <h6>Address</h6>
        <div>
          Wat Prachum Sako Rd, Sangkat Chroy Chang Va, Phnom Penh, Cambodia
          12110
        </div>
      </Col>
    </Row>
  </Container>;
};


export default AboutUs;