export const DEV_MODE = process.env.REACT_APP_DEV_MODE === "on";

export const MASTER_DATA = {
  DEFAULT_ITEM_PER_PAGE: 20
};

/**
 * All the paths are declare here.
 */
export const PATHS = {
  HOME: "/",
  SHOP: "/shop",
  MACHINE: "/machine",
  PRODUCT: "/product",
  DOWNLOAD: "/download",
  ABOUT_US: "/about-us",
  THREE_D_PRINTER: "/machine?category=3D+Printer",
  CO2_LASER: "/machine?category=C02+Laser",
  LASER_DIODE: "/machine?category=Laser+Diode",
  FIBER_MAKING: "/machine?category=Fiber+Making",
  CNC_ROUTER: "/machine?category=CNC+Router",
  FOAM_CUTTING: "/machine?category=Foam+Cutting",
  LOGO: "/logo.png",
  LOGO_OUTLINE: "/logo-outline.png",
  NO_IMAGE: "/no-image.png"
};

/**
 * The key is "Main Menu", the value is list of "Sub Menu".
 * Change the object will effect to nav bar.
 */
export const NAV_BAR_SUB_MENU = {
  [PATHS.MACHINE]: [
    { pathname: "machine", search: "", id: "All Machines", },
    { pathname: "machine", search: "?category=3D+Printer", id: "3D Printer", },
    { pathname: "machine", search: "?category=C02+Laser", id: "C02 Laser", },
    { pathname: "machine", search: "?category=Laser+Diode", id: "Laser Diode", },
    { pathname: "machine", search: "?category=Fiber+Making", id: "Fiber Making", },
    { pathname: "machine", search: "?category=CNC+Router", id: "CNC Router", },
    { pathname: "machine", search: "?category=Foam+Cutting", id: "Foam Cutting", },
  ]
};

/**
 * Backend endpoint
 */
export const API_PATHS = {
  HOME_CAROUSEL: "home-carousel",
  BLOG: "blog",
  SHOP: "shop",
  PRODUCT: "product",
  MACHINE: "machine",
  TAG: "tag",
  CATEGORY: "category",
  ITEM: "item",
};