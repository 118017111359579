import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

type LoadMoreButtonProp = {
  total: number;
  perPage: number;
  currentItem: number;
  setThresholdIndex: Dispatch<SetStateAction<number>>;
};

const LoadMoreButton = (prop: LoadMoreButtonProp) => {

  const [loading, setLoading] = useState(false);

  const onLoadMore = (e: any) => {
    e.preventDefault();

    setLoading(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
        prop.setThresholdIndex(prop.currentItem + prop.perPage);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [loading, prop]);

  const noMoreItem = prop.total === prop.currentItem;

  return (
    <div className="d-flex justify-content-center mt-5">
      {
        prop.total === 0 ? <div className="text-secondary">No items</div>
          : noMoreItem
            ? <div className="text-secondary">No more items</div>
            : <Button
              disabled={loading || noMoreItem}
              variant={"warning"}
              className="load-more-btn"
              onClick={onLoadMore}
              style={{ width: "150px" }}
            >
              {loading ?
                <>
                  <Spinner size="sm" />
                  {" Loading"}
                </>
                : <>Load more</>
              }
            </Button>
      }
    </div>
  );
};

export default LoadMoreButton;
